export function getFiveStructure(natal) {
    let percentArray = [0, 0, 0, 0, 0];
    let haveHour = natal.hour.animal.code !== 0;
    let notHiddenCellCount = haveHour ? 8 : 6;
    let addValue = 80 / notHiddenCellCount;

    const slots = ['hour', 'day', 'month', 'year'];
    for (let slot of slots) {
        if (natal[slot]) {
            percentArray[(natal[slot].heaven_element.code - 1) % 5] += addValue;
            percentArray[(natal[slot].earth_element.code - 1) % 5] += addValue;

            if (natal[slot].hidden_stem) {
                let computedCap = getCap(natal[slot].hidden_stem, 20 / (notHiddenCellCount / 2));
                natal[slot].hidden_stem.forEach(stem => {
                    percentArray[(stem.element.code - 1) % 5] += computedCap;
                });
            }
        }
    }

    return [percentArray[2], percentArray[3], percentArray[0], percentArray[1], percentArray[4]];
}

const getCap = (hiddenStemArray, hiddenStemValue) => Number(hiddenStemValue / hiddenStemArray.length);
