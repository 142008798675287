import axios from 'axios'
import store from '../store'

// default path user
const instance = axios.create({
  baseURL: 'https://numeiang-core-admin-aj3agejwkq-as.a.run.app',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use((config) => {
  if (store.getters.isAuthenticated) {
    config.headers.Authorization = store.getters.token
      ? `Bearer ${store.getters.token}`
      : ''
  }
  return config
})

export default instance
