import axios from '@/api/axios-date-selection2.js'
import calendarAxios from '@/api/axios-calendar'
import moment from 'moment'

const state = {
  isLoading: true,
  isSubmitting: false,
  formErrorMessage: null,
  dateSelectionDetails: {},
}

const mutations = {
  setDateSelectionDetails(state, dateSelectionDetails) {
    state.dateSelectionDetails = dateSelectionDetails
    state.isLoading = false
  },
  setLoading(state, value) {
    state.isLoading = value
  },
  setSubmitting(state, value) {
    if (value) {
      state.formErrorMessage = null
    }
    state.isSubmitting = value
  },
  setFormErrorMessage(state, value) {
    state.formErrorMessage = value
  },
}

const actions = {
  async loadDateSelectionDetails({ commit }, { id }) {
    commit('setLoading', true)
    try {
      const response = await axios.get(`/auspicious-dates/${id}`)
      const json = response.data
      const members = getMembers(json)
      await enrichMembersWithBaziData(members)

      const owners = {
        label: getLabelByType(json.type),
        members,
      }

      const confirmationInfo = parseConfirmationInfo(json)
      const dateSelectionDetails = await formatDateSelectionDetails(
        json,
        owners,
        confirmationInfo
      )

      commit('setDateSelectionDetails', dateSelectionDetails)
    } catch (error) {
      console.error('Error loading date selection details:', error)
    } finally {
      commit('setLoading', false)
    }
  },
  async submitDateSelectionsForm({ commit }, request) {
    commit('setSubmitting', true)
    try {
      const { id } = request
      const parsedRequestBody = parseRequest(request)
      await axios.post(
        `/auspicious-dates/${id}/auspicious-dates`,
        parsedRequestBody
      )
    } catch (error) {
      commit('setFormErrorMessage', `${error}`)
    }
    commit('setSubmitting', false)
  },
}

const getters = {
  dateSelectionDetailsIsLoading: (state) => state.isLoading,
  dateSelectionDetails: (state) => state.dateSelectionDetails,
  isDateSelectionFormSubmitting: (state) => state.isSubmitting,
  formErrorMessage: (state) => state.formErrorMessage,
}

const parseRequest = (request) => {
  if (request.requestType === 'new_car') {
    return {
      remark: request.suggestion,
      is_auto_select: true,
      new_car: {
        lucky_colors: [
          request.selectedColors.auspiciousOne.id,
          request.selectedColors.auspiciousTwo.id,
        ].filter(Boolean),
        lucky_numbers: request.selectedNumbers.filter(Boolean),
        auspicious_dates: request.selectedDates.map((it) => {
          return {
            [it.key]: {
              date: moment(it.start_date_time).format('yyyy-MM-DD'),
              start_time: moment(it.start_date_time).format('hh:mm'),
              end_time: moment(it.end_date_time).format('hh:mm'),
            },
          }
        }),
      },
    }
  }

  if (request.requestType === 'birth') {
    return {
      remark: request.suggestion,
      is_auto_select: true,
      birth: {
        auspicious_dates: request.selectedDates.map((it) => {
          const data = it
          return {
            [data.key]: {
              date: moment(data.auspicious_start_date_time).format(
                'yyyy-MM-DD'
              ),
              start_time: moment(data.auspicious_start_date_time).format(
                'HH:mm'
              ),
              end_time: moment(data.auspicious_end_date_time).format('HH:mm'),
            },
          }
        }),
      },
    }
  }

  return request
}

const getMembers = (json) => {
  const typeDetails = json[json.type]?.detail || {}
  const structureKeys = [
    'members',
    'bride',
    'bride_father',
    'bride_mother',
    'groom',
    'groom_father',
    'groom_mother',
    'father',
    'mother',
    'person',
  ]
  const structureKey = structureKeys.find((key) => typeDetails[key])

  const formatMember = (member, title) => {
    if (member?.date_of_birth) {
      return {
        title,
        name: `${member.first_name} ${member.last_name}`,
        dob: member.date_of_birth,
        tob: member.time_of_birth || null,
        extra: formatExtraInfo(member),
      }
    }
    return null
  }

  const formatExtraInfo = (member) => {
    const notAllowedKeys = [
      'first_name',
      'last_name',
      'date_of_birth',
      'time_of_birth',
    ]
    const extraInfo = Object.entries(member)
      .filter(([key]) => !notAllowedKeys.includes(key))
      .map(([key, value]) => ({
        label: formatKeyToLabel(key),
        key,
        value,
      }))

    return extraInfo.length ? extraInfo : undefined
  }

  const formatKeyToLabel = (key) =>
    key
      .split('_')
      .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
      .join(' ')

  const formatTitleByKey = (key) => {
    const titleMap = {
      father: 'Father',
      mother: 'Mother',
      bride: 'Bride',
      bride_father: "Bride's Father",
      bride_mother: "Bride's Mother",
      groom: 'Groom',
      groom_father: "Groom's Father",
      groom_mother: "Groom's Mother",
      person: 'Requester',
    }
    return titleMap[key] || key
  }

  const formatTitleByType = (type) => {
    const titleMap = {
      new_car: 'Car Own',
    }
    return titleMap[type] || formatKeyToLabel(type)
  }

  const members = structureKeys
    .filter((key) => typeDetails[key])
    .flatMap((key) => {
      const data = typeDetails[key]
      if (Array.isArray(data)) {
        return data
          .map((item) => formatMember(item, formatTitleByType(json.type)))
          .filter(Boolean)
      } else {
        return [formatMember(data, formatTitleByKey(key))]
      }
    })
    .filter(Boolean) // remove empty object

  if (json.type === 'birth') {
    members.push({
      title: 'Baby',
      extra: [
        {
          label: 'Year of Birth',
          value: typeDetails.baby_year_of_birth,
          key: 'baby_date_of_birth',
        },
        { label: 'Gender', value: typeDetails.baby_gender, key: 'baby_gender' },
      ],
    })
  }
  return members
}

const enrichMembersWithBaziData = async (members) => {
  for (const member of members) {
    if (!member.dob) continue

    member.tob = isValidTimeFormat(member.tob) ? member.tob : null
    try {
      let path =
        `/bazis/${member.dob}` + (member.tob ? `?time=${member.tob}` : '')
      const res = await calendarAxios.get(path)
      const data = res.data
      member.fiveStructure = await getFiveStructure(member.dob, member.tob)
      member.heavenly = getHeavenlyData(data)
      member.earthly = getEarthlyData(data)
    } catch (error) {
      console.error('Error fetching Bazi data:', error)
    }
  }
}

const isValidTimeFormat = (timeString) =>
  moment(timeString, 'HH:mm', true).isValid()

const getFiveStructure = async (dob, tob) => {
  const response = await calendarAxios.get(
    `/bazis/${dob}/five-structure` + (tob ? `?time=${tob}` : '')
  )
  const { earth, fire, metal, water, wood } = response.data
  // ['Metal', 'Water', 'Wood', 'Fire', 'Earth']
  return [metal, water, wood, fire, earth]
}

const getHeavenlyData = (data) =>
  ['hour', 'day', 'month', 'year'].map((slot) => ({
    title: data[slot]?.heavenly_stem?.mandarin || '',
    subtitle: `${data[slot]?.heavenly_stem?.element?.mandarin || ''} ${data[slot]?.heavenly_stem?.element?.name_en || ''}`,
    description: data[slot]?.heavenly_stem?.pinyin || '',
    labelEn: data[slot]?.heavenly_stem?.ten_god?.name_en_abbr || '',
    labelZh: data[slot]?.heavenly_stem?.ten_god?.mandarin || '',
  }))

const getEarthlyData = (data) =>
  ['hour', 'day', 'month', 'year'].map((slot) => ({
    title: data[slot]?.earthly_branch?.mandarin || '',
    subtitle: `${data[slot]?.earthly_branch?.element?.mandarin || ''} ${data[slot]?.earthly_branch?.element?.name_en || ''}`,
    description: data[slot]?.earthly_branch?.name || '',
  }))

const getLabelByType = (type) =>
  ({
    new_car: "Car Owner's Information",
    birth: "Family's Information",
  })[type] || "Requester's Information"

const parseConfirmationInfo = (data) => {
  const babyInfo = data[data.type]['baby_info']
  const carInfo = data[data.type]['actual_date']?.car_purchasing

  const info = {
    new_car: { title: 'Date of Car Purchase', label: 'Date of Purchase' },
    birth: { title: "Baby's Date of Birth", label: 'Date of Birth' },
  }[data?.type]

  if (data.status === 'date_confirming') {
    return {
      ...info,
      isConfirmed: false,
    }
  }

  if (data?.type === 'birth' && babyInfo) {
    const formattedDate = moment(babyInfo.date_of_birth).format('DD MMM YYYY')
    let formattedTime = ''

    if (babyInfo.time_of_birth) {
      formattedTime = ` • ${moment(babyInfo.time_of_birth, 'HH:mm').format('hh:mmA')}`
    }

    return {
      ...info,
      value: `${formattedDate}${formattedTime}`,
      isConfirmed: true,
    }
  }

  if (data?.type === 'new_car' && carInfo) {
    const formattedDate = moment(carInfo.date).format('DD MMM YYYY')
    const startTime = moment(carInfo.start_time, 'HH:mm').format('hh:mmA')
    const endTime = moment(carInfo.end_time, 'HH:mm').format('hh:mmA')

    return {
      ...info,
      value: `${formattedDate} | ${startTime} - ${endTime}`,
      isConfirmed: true,
    }
  }

  return null
}

const parseAuspiciousDates = (json, key) => {
  let parsedDates = []
  const details = json[json.type]
  if (details && details[key] && details[key].length > 0) {
    json[json.type][key].forEach((dateEntry) => {
      const key = Object.keys(dateEntry)[0]
      if (key && dateEntry[key]) {
        const data = dateEntry[key]
        const auspiciousStartDateTime = `${data.auspicious_date.date}T${data.auspicious_date.start_time}`
        const auspiciousEndDateTime = `${data.auspicious_date.date}T${data.auspicious_date.end_time}`
        const title = `Auspicious date for ${json.type}`
        const parsedEntry = {
          title,
          start_date_time: auspiciousStartDateTime,
          end_date_time: auspiciousEndDateTime,
          key,
          officer: data.officer || '',
          constellation: {
            name: data.constellation || '',
            types: data.tags || [],
          },
        }
        parsedDates.push(parsedEntry)
      }
    })
  }

  return parsedDates
}

const formatDateSelectionDetails = async function (
  json,
  owners,
  confirmationInfo
) {
  const selectedDates = parseAuspiciousDates(json, 'admin_selected_dates')
  const suggestedDates = parseAuspiciousDates(json, 'auto_selected_dates')
  const suggestedColors = await getSuggestedColors(json)
  const selectedColors = parseSelectedColors(json)

  return {
    id: json.id,
    requestNumber: json.display_id,
    requestType: json.type,
    status: json.status,
    requestDate: moment(json.created_at).format('DD MMM YYYY • HH:mm'),
    userId: json.user_id,
    price: json.amount,
    note: json.additional_info,
    requestPeriod: json.preferred_date_ranges.map(
      ({ start_date, end_date }) =>
        `${moment(start_date).format('DD MMM YYYY')} to ${moment(end_date).format('DD MMM YYYY')}`
    ),
    isWeekDays: json.day_types.includes('weekday'),
    isWeekend: json.day_types.includes('weekend'),
    additionalImages: json.attachment_urls || [],
    paymentSlip: { label: 'Payment Slip', url: json.slip_url },
    infoItems: [
      { label: 'Name', value: json.user_name },
      { label: 'Phone Number', value: json.contact_phone },
      { label: 'Address', value: Object.values(json.address || {}).join(', ') },
    ],
    suggestedDates,
    selectedDates,
    owners,
    confirmationInfo,
    suggestedNumbers: getSuggestedNumbers(json),
    selectedNumbers: getSelectedNumbers(json),
    suggestedColors,
    selectedColors,
    suggestion: json.remark,
    isAutoMode: json.is_auto_select,
  }
}

const getSelectedNumbers = (json) => {
  if (json.type === 'new_car') {
    return json[json.type].lucky_numbers
  }

  return []
}

const getSuggestedColors = async (json) => {
  if (json.type === 'new_car') {
    const { data } = await calendarAxios.get('/colors')
    return [
      {
        label: 'Colors',
        colors: data,
      },
    ]
  }

  return []
}

const parseSelectedColors = (json) => {
  const type = json.type
  if (type === 'new_car') {
    const [one, two] = json[type].lucky_colors
    return {
      auspiciousOne: one || null,
      auspiciousTwo: two || null,
      inauspiciousName: json[type].strongest_element,
    }
  }

  return {
    auspiciousOne: null,
    auspiciousTwo: null,
    inauspiciousName: json[type]?.strongest_element || null,
  }
}

const getSuggestedNumbers = (json) => {
  if (json.type === 'new_car') {
    return new Array(10).fill(null).map((_, index) => index)
  }

  return []
}

export default {
  state,
  mutations,
  actions,
  getters,
}
