import axios from '@/api/axios-notifications'
import qs from 'qs'

const state = {
  notification: undefined,
  notifications: [],
  allNotifications: [],
  searchNotifications: [],
  notificationsTotalPage: 0,
  searchNotificationsTotalPage: 0,
  notificationIsLoading: false,
  notificationsIsLoading: false,
  allNotificationsIsLoading: false,
  searchNotificationsIsLoading: false,
}

const mutations = {
  setNotification(state, notification) {
    state.notification = notification
  },
  setNotifications(state, notifications) {
    state.notifications = notifications
  },
  setSearchNotifications(state, notifications) {
    state.searchNotifications = notifications
  },
  setNotificationsTotalPage(state, pages) {
    state.notificationsTotalPage = pages
  },
  setSearchNotificationsTotalPage(state, pages) {
    state.searchNotificationsTotalPage = pages
  },
  setNotificationIsLoading(state, status) {
    state.notificationIsLoading = status
  },
  setNotificationsIsLoading(state, status) {
    state.notificationsIsLoading = status
  },
  setAllNotifications(state, allNotifications) {
    state.allNotifications = allNotifications
  },
  setAllNotificationsIsLoading(state, status) {
    state.allNotificationsIsLoading = status
  },
  setSearchNotificationsIsLoading(state, status) {
    state.searchNotificationsIsLoading = status
  },
}

const actions = {
  getNotification: async (
    { commit, getters },
    { page = 1, limit = 1, query = null }
  ) => {
    commit('setNotificationIsLoading', true)
    let params = {
      page: page,
      limit: limit,
      query: query,
    }

    // Filter out properties with null values
    params = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== null)
    )

    await axios
      .get('/notification-trigger/history', {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
      })
      .then((res) => {
        if (res.data.notifications && res.data.notifications.length > 0) {
          commit('setNotification', res.data.notifications[0])
        } else {
          commit('setNotification', undefined)
        }
        commit('setNotificationIsLoading', false)
      })
      .catch((err) => {
        // alert(err);
        commit('setNotificationIsLoading', false)
      })
  },
  getNotifications: async (
    { commit, getters },
    {
      page = 1,
      limit = 15,
      query = null,
      features = null,
      triggers = null,
      userType = null,
      start = null,
      end = null,
    }
  ) => {
    commit('setNotificationsIsLoading', true)
    let params = {
      page: page,
      limit: limit,
      query: query,
      userType: userType,
      features: features,
      triggers: triggers,
      start: start,
      end: end,
    }

    // Filter out properties with null values
    params = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== null)
    )

    await axios
      .get('/notification-trigger/history', {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
      })
      .then((res) => {
        if (res.data.notifications !== null) {
          commit('setNotifications', res.data.notifications)
          commit('setNotificationsTotalPage', res.data.meta.pagination.pages)
        } else {
          commit('setNotifications', [])
        }
        commit('setNotificationsIsLoading', false)
      })
      .catch((err) => {
        // alert(err);
        commit('setNotificationsIsLoading', false)
      })
  },
  getSearchNotifications: async (
    { commit, getters },
    { page = 1, limit = 15, query = null }
  ) => {
    commit('setSearchNotificationsIsLoading', true)
    let params = {
      page: page,
      limit: limit,
      query: query,
    }

    // Filter out properties with null values
    params = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== null)
    )

    await axios
      .get('/notification-trigger/history', {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
      })
      .then((res) => {
        if (res.data.notifications !== null) {
          commit('setSearchNotifications', res.data.notifications)
          commit(
            'setSearchNotificationsTotalPage',
            res.data.meta.pagination.pages
          )
        } else {
          commit('setSearchNotifications', [])
        }
        commit('setSearchNotificationsIsLoading', false)
      })
      .catch((err) => {
        // alert(err);
        commit('setSearchNotificationsIsLoading', false)
      })
  },
  getAllNotifications: async (
    { commit, getters },
    {
      query = null,
      features = null,
      triggers = null,
      userType = null,
      start = null,
      end = null,
    }
  ) => {
    commit('setAllNotificationsIsLoading', true)
    let params = {
      query: query,
      userType: userType,
      features: features,
      triggers: triggers,
      start: start,
      end: end,
    }
    // Filter out properties with null values
    params = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== null)
    )

    await axios
      .get('/notification-trigger/history-all', {
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        },
      })
      .then((res) => {
        if (res.data.notifications !== null) {
          commit('setAllNotifications', res.data.notifications)
        } else {
          commit('setAllNotifications', [])
        }
        commit('setAllNotificationsIsLoading', false)
      })
      .catch((err) => {
        // alert(err);
        commit('setAllNotificationsIsLoading', false)
      })
  },
}

const getters = {
  notification(state) {
    return state.notification
  },
  notifications(state) {
    return state.notifications
  },
  notificationsTotalpage(state) {
    return state.notificationsTotalPage
  },
  notificationIsloading(state) {
    return state.notificationIsLoading
  },
  notificationsIsloading(state) {
    return state.notificationsIsLoading
  },
  allNotifications(state) {
    return state.allNotifications
  },
  allNotificationsIsLoading(state) {
    return state.allNotificationsIsLoading
  },
  searchNotifications(state) {
    return state.searchNotifications
  },
  searchNotificationsIsLoading(state) {
    return state.searchNotificationsIsLoading
  },
  searchNotificationsTotalPage(state) {
    return state.searchNotificationsTotalPage
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
