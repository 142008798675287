import axios from '@/api/axios-calendar'
import zodiacParse from '@/assets/calendar/calendar-zodiac.js'

const state = {
  calendar: [],
  events: [],
  allVersion: [],
  currentVersion: null,
  unPublishVersion: null,
  isLoadingCalendar: false,
}

const mutations = {
  addEvent(state, event) {
    state.events.push(event)
  },
  setCalendar(state, calendar) {
    state.calendar = calendar
  },
  setAllVersion(state, versions) {
    state.allVersion = versions
  },
  setCurrentVersion(state, version) {
    state.currentVersion = version
  },
  setUnPublishVersion(state, version) {
    state.unPublishVersion = version
  },
  setIsLoadingCalendar(state, loading) {
    state.isLoadingCalendar = loading
  },
}

const actions = {
  getCalendar: async ({ commit, getters }) => {
    /* 
      event ประกอบด้วย {
        event_name: บอกชนิดวันไว้ classify วัน
        name: ชื่อวันที่แสดงในปฏิทิน
        start: วันที่จะแสดง
      }
    */
    commit('setIsLoadingCalendar', true)
    return new Promise((resolve, reject) => {
      axios
        // .get('https://api.admin.numeiang.app/calendar')
        .get('/calendar')
        .then((res) => {
          commit('setCalendar', res.data)
          let calendar = res.data
          // ขึ้น-แรม
          let moon_phase = calendar.map((cal) => {
            return {
              start: cal.date,
              day: cal.day_th,
              month: cal.month_th,
              moon_phase: cal.moon_phase,
            }
          })

          moon_phase.forEach((data) => {
            commit('addEvent', {
              event_name: 'moon',
              moonPhase: data.moon_phase,
              day: data.day,
              month: data.month,
              name: `${data.moon_phase} ${data.day} ค่ำ เดือน ${data.month}`,
              start: data.start,
              color: 'indigo',
            })
          })

          // วันพระไทย
          let buddhist_day = calendar.map((cal) => {
            return {
              start: cal.date,
              name: cal.buddhist_day_th,
            }
          })
          let filter = buddhist_day.filter((data) => {
            return data.name === true
          })
          filter.forEach((data) => {
            commit('addEvent', {
              name: data.name === true ? 'วันพระ' : null,
              start: data.start,
              color: 'red darken-3',
            })
          })

          // วันพระสำคัญของไทย
          let th_important_buddhist_day = calendar.map((cal) => {
            return {
              start: cal.date,
              name: cal.important_buddhist_day_th,
            }
          })

          filter = th_important_buddhist_day.filter((data) => {
            return data.name.length !== 0
          })

          filter.forEach((data) => {
            commit('addEvent', {
              name: data.name,
              start: data.start,
              color: 'red darken-3',
            })
          })

          // วันพระจีน
          let ch_buddhist_day = calendar.map((cal) => {
            return {
              start: cal.date,
              name: cal.buddhist_day_ch,
            }
          })

          ch_buddhist_day = ch_buddhist_day.filter((data) => {
            return data.name === true
          })

          ch_buddhist_day.forEach((data) =>
            commit('addEvent', {
              name: data.name === true ? 'วันพระจีน' : null,
              start: data.start,
              color: 'orange darken-4',
            })
          )

          // วันธงไชย
          let auspicious_day = calendar.map((data) => {
            return {
              name: data.auspicious_day,
              start: data.date,
            }
          })

          filter = auspicious_day.filter((data) => {
            return data.name === true
          })

          filter.forEach((data) => {
            commit('addEvent', {
              name: data.name === true ? 'ธงไชย' : null,
              start: data.start,
              color: 'amber darken-4',
            })
          })

          // วันไหว้ (คำอ่านไทย)
          let important_day_name_th_ch = calendar.map((cal) => {
            return {
              name: cal.important_day_name_th_ch,
              start: cal.date,
            }
          })

          filter = important_day_name_th_ch.filter((cal) => {
            return cal.name.length !== 0
          })

          filter.forEach((cal) => {
            commit('addEvent', {
              start: cal.start,
              name: cal.name,
              color: 'brown darken-1',
            })
          })

          // วันสำคัญไทย
          let important_day_th = calendar.map((cal) => {
            return {
              name: cal.important_day_th,
              start: cal.date,
            }
          })

          filter = important_day_th.filter((cal) => {
            return cal.name.length > 2
          })

          filter.forEach((cal) => {
            commit('addEvent', {
              start: cal.start,
              name: cal.name,
              color: 'blue darken-2',
            })
          })
          // ปีนักษัตร
          let zodiac = calendar.map((cal) => {
            return {
              start: cal.date,
              zodiac: zodiacParse[cal.zodiac_year],
            }
          })
          zodiac.forEach((data) => {
            commit('addEvent', {
              name: data.zodiac,
              start: data.start,
              color: 'light-green darken-1',
            })
          })
          commit('setIsLoadingCalendar', false)
          resolve({ status: 'success' })
        })
        .catch((err) => {
          resolve({ status: 'error' })
        })
    })
  },

  getCurrentVersion: async ({ commit }) => {
    await axios
      .get('/calendar/version')
      .then((res) => {
        commit('setCurrentVersion', res.data.version)
      })
      .catch((err) => {
        alert(err)
      })
  },

  getUnPublishVersion: async ({ commit }) => {
    await axios
      .get('/calendar/unpublished-version')
      .then((res) => {
        commit('setUnPublishVersion', res.data.version)
      })
      .catch((err) => {
        alert(err)
      })
  },

  getAllVersion: async ({ commit }) => {
    await axios
      .get('/calendar/all-version')
      .then((res) => {
        commit('setAllVersion', res.data)
      })
      .catch((err) => {
        alert(err)
      })
  },

  updateDate: async ({ commit }, dateData) => {
    await axios
      .put('/calendar', dateData)
      .then((res) => {})
      .catch((err) => {
        alert(err)
      })
  },

  uploadCSV: async ({ commit, dispatch }, formData) => {
    await axios
      .post('/calendar', formData.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        dispatch('setAlertMessage', {
          message: 'อัพโหลดสำเร็จ',
          type: 'success',
        })
      })
      .catch((err) => {
        this.$refs.fileInput.remove()
        dispatch('setAlertMessage', {
          message: 'เกิดข้อผิดพลาด',
          type: 'error',
        })
      })
  },

  publishCalendar: async ({ commit, dispatch }, changelog) => {
    await axios
      .put('/calendar/version', {
        changelog: changelog.changelogText,
      })
      .then((res) => {
        dispatch('setAlertMessage', {
          message: 'Publish ปฏิทินแล้ว',
          type: 'success',
        })
      })
      .catch((err) => {
        dispatch('setAlertMessage', {
          message: 'เกิดข้อผิดพลาด',
          type: 'error',
        })
      })
  },
}

const getters = {
  calendar: (state) => {
    return state.calendar
  },
  events: (state) => {
    return state.events
  },
  allVersion: (state) => {
    return state.allVersion
  },
  currentVersion: (state) => {
    return state.currentVersion
  },
  unPublishVersion: (state) => {
    return state.unPublishVersion
  },
  isLoadingCalendar: (state) => {
    return state.isLoadingCalendar
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
